/* tslint:disable */
/* eslint-disable */
/**
 * Agenda Bank API
 * API do sistema de consignações previdenciárias - Agenda Bank
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base'
// @ts-ignore
import { GetPesquisarUsuarioDto } from '../models'
// @ts-ignore
import { VerifyPassword } from '../models'
/**
 * UsuarioApi - axios parameter creator
 * @export
 */
export const UsuarioApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ativarUsuario: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/user/ativarUsuario/{id}`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Atualiza ou cria a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizarFotoDePerfil: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('atualizarFotoDePerfil', 'id', id)
      const localVarPath = `/cadastro/user/{id}/foto-perfil`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscaPorInstitutoId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('buscaPorInstitutoId', 'id', id)
      const localVarPath = `/cadastro/user/buscaPorInstitutoId/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Busca a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscarFotoDePerfil: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('buscarFotoDePerfil', 'id', id)
      const localVarPath = `/cadastro/user/{id}/foto-perfil`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cadastrarFoto: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/user/cadastrarFoto`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    desativarUsuario: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/user/desativarUsuario/{id}`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getId', 'id', id)
      const localVarPath = `/cadastro/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPesquisarUsuarioDto} getPesquisarUsuarioDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarUsuario: async (
      getPesquisarUsuarioDto: GetPesquisarUsuarioDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPesquisarUsuarioDto' is not null or undefined
      assertParamExists('getPesquisarUsuario', 'getPesquisarUsuarioDto', getPesquisarUsuarioDto)
      const localVarPath = `/cadastro/user/GetPesquisarUsuario`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getPesquisarUsuarioDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorioUsuario: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/user/GetRelatorioUsuario`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    limparFoto: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('limparFoto', 'id', id)
      const localVarPath = `/cadastro/user/limparFoto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listar: async (institutoId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'institutoId' is not null or undefined
      assertParamExists('listar', 'institutoId', institutoId)
      const localVarPath = `/cadastro/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (institutoId !== undefined) {
        localVarQueryParameter['institutoId'] = institutoId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/cadastro/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Remove a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removerFotoDePerfil: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('removerFotoDePerfil', 'id', id)
      const localVarPath = `/cadastro/user/{id}/foto-perfil`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSenha: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSenha', 'id', id)
      const localVarPath = `/cadastro/user/updateSenha/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsuario: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateUsuario', 'id', id)
      const localVarPath = `/cadastro/user/updateUsuario/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {VerifyPassword} verifyPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPassword: async (verifyPassword: VerifyPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'verifyPassword' is not null or undefined
      assertParamExists('verifyPassword', 'verifyPassword', verifyPassword)
      const localVarPath = `/cadastro/user/GetverifyPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(verifyPassword, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UsuarioApi - functional programming interface
 * @export
 */
export const UsuarioApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsuarioApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ativarUsuario(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ativarUsuario(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.ativarUsuario']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary Atualiza ou cria a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async atualizarFotoDePerfil(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.atualizarFotoDePerfil(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.atualizarFotoDePerfil']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buscaPorInstitutoId(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buscaPorInstitutoId(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.buscaPorInstitutoId']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary Busca a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buscarFotoDePerfil(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buscarFotoDePerfil(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.buscarFotoDePerfil']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cadastrarFoto(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cadastrarFoto(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.cadastrarFoto']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async desativarUsuario(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.desativarUsuario(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.desativarUsuario']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getId(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getId(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.getId']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetPesquisarUsuarioDto} getPesquisarUsuarioDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarUsuario(
      getPesquisarUsuarioDto: GetPesquisarUsuarioDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarUsuario(getPesquisarUsuarioDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.getPesquisarUsuario']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatorioUsuario(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioUsuario(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.getRelatorioUsuario']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async limparFoto(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.limparFoto(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.limparFoto']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listar(
      institutoId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listar(institutoId, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.listar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.login']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.put']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @summary Remove a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removerFotoDePerfil(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removerFotoDePerfil(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.removerFotoDePerfil']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSenha(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSenha(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.updateSenha']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUsuario(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsuario(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.updateUsuario']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {VerifyPassword} verifyPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyPassword(
      verifyPassword: VerifyPassword,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPassword(verifyPassword, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['UsuarioApi.verifyPassword']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
  }
}

/**
 * UsuarioApi - factory interface
 * @export
 */
export const UsuarioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsuarioApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ativarUsuario(options?: any): AxiosPromise<void> {
      return localVarFp.ativarUsuario(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Atualiza ou cria a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizarFotoDePerfil(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.atualizarFotoDePerfil(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscaPorInstitutoId(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.buscaPorInstitutoId(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Busca a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscarFotoDePerfil(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.buscarFotoDePerfil(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cadastrarFoto(options?: any): AxiosPromise<void> {
      return localVarFp.cadastrarFoto(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    desativarUsuario(options?: any): AxiosPromise<void> {
      return localVarFp.desativarUsuario(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getId(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPesquisarUsuarioDto} getPesquisarUsuarioDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarUsuario(getPesquisarUsuarioDto: GetPesquisarUsuarioDto, options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarUsuario(getPesquisarUsuarioDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorioUsuario(options?: any): AxiosPromise<void> {
      return localVarFp.getRelatorioUsuario(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    limparFoto(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.limparFoto(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listar(institutoId: string, options?: any): AxiosPromise<void> {
      return localVarFp.listar(institutoId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(options?: any): AxiosPromise<void> {
      return localVarFp.login(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.put(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Remove a foto de perfil de um usuário
     * @param {string} id User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removerFotoDePerfil(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.removerFotoDePerfil(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSenha(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.updateSenha(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUsuario(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.updateUsuario(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {VerifyPassword} verifyPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPassword(verifyPassword: VerifyPassword, options?: any): AxiosPromise<void> {
      return localVarFp.verifyPassword(verifyPassword, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * UsuarioApi - object-oriented interface
 * @export
 * @class UsuarioApi
 * @extends {BaseAPI}
 */
export class UsuarioApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public ativarUsuario(options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .ativarUsuario(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Atualiza ou cria a foto de perfil de um usuário
   * @param {string} id User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public atualizarFotoDePerfil(id: string, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .atualizarFotoDePerfil(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public buscaPorInstitutoId(id: number, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .buscaPorInstitutoId(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Busca a foto de perfil de um usuário
   * @param {string} id User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public buscarFotoDePerfil(id: string, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .buscarFotoDePerfil(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public cadastrarFoto(options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .cadastrarFoto(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public desativarUsuario(options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .desativarUsuario(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public getId(id: number, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .getId(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPesquisarUsuarioDto} getPesquisarUsuarioDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public getPesquisarUsuario(getPesquisarUsuarioDto: GetPesquisarUsuarioDto, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .getPesquisarUsuario(getPesquisarUsuarioDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public getRelatorioUsuario(options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .getRelatorioUsuario(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public limparFoto(id: number, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .limparFoto(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} institutoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public listar(institutoId: string, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .listar(institutoId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public login(options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .login(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public put(id: number, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .put(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Remove a foto de perfil de um usuário
   * @param {string} id User ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public removerFotoDePerfil(id: string, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .removerFotoDePerfil(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public updateSenha(id: number, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .updateSenha(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public updateUsuario(id: number, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .updateUsuario(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {VerifyPassword} verifyPassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsuarioApi
   */
  public verifyPassword(verifyPassword: VerifyPassword, options?: RawAxiosRequestConfig) {
    return UsuarioApiFp(this.configuration)
      .verifyPassword(verifyPassword, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
