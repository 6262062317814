<template>
  <!-- RELATÓRIOS PDF -->
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="800"
      filename="Agenda Bank - Relatório"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded"
      @startPagination="startPagination"
      foot-row-class="bold-footer-row"
      ref="relatorio"
    >
      <section slot="pdf-content" style="background-color: white">
        <b-card class="fundoRelatorio cabecalhoTable">
          <div v-for="(linhaDaTabela, indice) in linhasTabela" :key="`tabelaItem_${indice}`">
            <div class="fundoRelatorio titulo">
              <div class="d-none d-lg-flex align-items-center fundoRelatorio">
                <!-- <b-img fluid :src="logoImg" alt="Logo da Agenda"></b-img> -->

                <img :src="logoAgenda" alt="Logo" />

                <!-- <img fluid :src="logoA" alt="Logo" /> -->
                <h2 class="brand-text text-primary ml-1" style="margin-bottom: 0px; margin-left: 0px !important">
                  Agenda Bank
                </h2>
              </div>
              <div class="lineBlue"></div>
              <div class="lineGray"></div>
              <h1 class="fundoRelatorio instituto" style="margin-bottom: 0px; margin-top: 5px">
                {{ userData.Instituto.nome }}
              </h1>

              <div class="fundoRelatorio tituloRelatorio">
                {{ 'Extrato de Arrecadação - Competência: ' + formatarCompetencia(dadoArrecadacaoFilho.competencia) }}
              </div>
              <div class="fundoRelatorio subtituloRelatorio">{{ dadoArrecadacaoFilho.orgao.nome }}</div>

              <div class="periodoRelatorio fundoRelatorio" style="margin-top: 18px; font-size: 12px">
                RESUMO POR TIPO DE PARCELA
              </div>
              <b-table
                striped
                responsive
                class="RelatorioJurosPDF"
                :class="linhasTabela.length - 1 == indice ? 'UltimaTable' : ''"
                style="text-align: center"
                id="tableTaxaJurosPDF"
                :head-variant="'light'"
                :items="linhaDaTabela"
                :fields="camposDaTabela"
              >
              </b-table>

              <div v-if="linhasTabela.length - 1 == indice && linhaDaTabela.length == 15" class="html2pdf__page-break" />
              <div :class="linhaDaTabela.length == 15 ? 'margemTotalizador' : ''">
                <div v-if="linhasTabela.length - 1 == indice" class="fundoRelatorio totalParcelas">
                  TOTAL DA ARRECADAÇÃO: {{ dadoArrecadacaoFilho.valorTotal }}
                </div>
              </div>
              <div v-if="linhasTabela.length - 1 != indice" class="html2pdf__page-break" />
            </div>
          </div>
        </b-card>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
  import Cleave from 'vue-cleave-component'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import { formatarCompetencia } from '@core/utils/utils.js'

  export default {
    components: {
      BCardCode,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      pdfMake,
      pdfFonts,
    },
    directives: {
      Ripple,
    },
    props: {
      table: {
        type: Array,
      },
      camposDaTabela: {
        type: Array,
      },
      dadoArrecadacaoFilho: {
        type: Object,
      },
    },
    data() {
      return {
        aImg: require('@/assets/images/logo/Alogo2.png'),
        formatarCompetencia,
        // Relatórios
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        htmlPdfOptions: {
          margin: [0, 0, 0.35, 0],
          html2canvas: {
            scale: 1,
            useCORS: true,
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'landscape',
          },
        },
        filtro: {},
        imagemLogo: '',
        required,
        dados: {
          dataInicial: '',
          dataFinal: '',
          tipoDePesquisa: 'periodo',
        },
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },

        competenciaOpcoes: [],

        optionsTipoDePesquisa: [
          { text: 'Período', value: 'periodo' },
          { text: 'Competência', value: 'competencia' },
        ],

        relatorioDeCompetencia: null,
        isBusy: false,
        acesso: {},
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        NaoGeradoGRPE: 1,
        GeradoGRPE: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'nome', label: 'TIPO DE RELATÓRIO' },
          { key: 'acoes', label: 'AÇÕES' },
        ],
        itemsRelatorio: {},
        colunasTabelaRelatorio: [],
        linhasTabela: [],
      }
    },
    computed: {
      logoAgenda() {
        return this.userData.Instituto?.logoRelatorio?.logo ?? this.aImg
      },
    },
    mounted() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.dados.tipoDePesquisa = 'periodo'

      this.dados.dataInicial = '0101' + new Date().getFullYear().toString()
      this.dados.dataFinal = '3112' + new Date().getFullYear().toString()

      this.dados.competenciaInicial = 'Janeiro/' + new Date().getFullYear().toString()
      this.dados.competenciaFinal = 'Dezembro/' + new Date().getFullYear().toString()
    },
    methods: {
      startPagination() {
        this.isBusy = true
      },
      hasDownloaded() {
        this.isBusy = false
      },
      boldRowClass(item, type) {
        return item.negrito ? 'font-weight-bold' : ''
      },
      async exportToPDF() {
        this.$refs.relatorio.generatePdf()
        await this.dividirLista(this.table, 15)
      },

      dividirLista(listaOriginal, tamanhoMaximo) {
        let primeiraPagina = listaOriginal.slice(0, tamanhoMaximo)
        const restante = listaOriginal.slice(tamanhoMaximo)
        const sublistas = [primeiraPagina]
        let sublistaAtual = []
        restante.forEach((item, indice) => {
          if (sublistaAtual.length < tamanhoMaximo) {
            sublistaAtual.push(item)
          } else {
            sublistas.push(sublistaAtual)
            sublistaAtual = [item]
          }

          if (indice === restante.length - 1) {
            sublistas.push(sublistaAtual)
          }
        })
        this.linhasTabela = sublistas
      },
      async beforeDownload({ html2pdf, options, pdfContent }) {
        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()

            for (let pagina = 1; pagina <= totalPages; pagina++) {
              pdf.setPage(pagina)
              pdf.setFontSize(10)
              pdf.setTextColor(150)

              pdf.text(
                'Pagina ' + pagina + ' de ' + totalPages,
                pdf.internal.pageSize.getWidth() * 0.88,
                pdf.internal.pageSize.getHeight() - 0.15,
              )

              pdf.setFontSize(7)
              pdf.text(
                `${this.userData.Instituto.logradouro}, ${this.userData.Instituto.numero}, ${this.userData.Instituto.bairro}, ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf} E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}`,
                pdf.internal.pageSize.getWidth() * 0.03,
                pdf.internal.pageSize.getHeight() - 0.15,
              )
            }
          })
          .save()

        this.isBusy = false
      },
    },
  }
</script>

<style>
  .fundoRelatorio {
    background-color: white;
    word-break: break-word;
  }

  .titulo {
    background-color: inherit !important;
    justify-content: flex-start;
    word-break: break-all;
    padding-top: 5px;
  }

  .RelatorioJurosPDF .thead-light th {
    background-color: #f3f2f700 !important;
    border-color: #4941a6;
    border-block-start: initial;
    color: #4941a6;
    font-size: 10px;
    padding: 10.5px 0;
    inline-size: max-content;
  }

  .lineBlue {
    width: 288px;
    height: 13px;
    background-color: #4941a6;
  }
  .lineGray {
    width: 563px;
    height: 13px;
    background-color: #b8c2cb;
  }
  .instituto {
    font-size: initial;
    padding-left: 20px;
  }
  .tituloRelatorio {
    text-align: center;
    font-weight: bold;
    font-size: large;
    color: #615771;
  }

  .subtituloRelatorio {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
  }
  .cabecalhoTable {
    margin-bottom: 0px;
    font-size: 12px;
    word-wrap: normal;
    white-space: pre-wrap;
  }

  .periodoRelatorio {
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .totalParcelas {
    font-weight: bold;
    color: black;
    font-size: 12px;
    padding-bottom: 2px;
    padding-left: 20px;
  }

  .rodapeRelatorio {
    font-size: 11px;
    margin-bottom: 0px;
    color: #736c81;
    margin-top: 5px;
    padding-left: 20px;
  }

  .rodapeRelatorioContainer {
    bottom: 0rem;
  }

  .content-wrapper {
    position: relative;
  }

  .bold-footer-row {
    font-weight: bold;
  }

  #tableTaxaJurosPDF tbody tr td {
    padding: 3px !important;
  }

  .UltimaTable tbody tr:last-child {
    font-weight: bold;
  }

  #logoAgenda {
    margin: 0 10px;
  }

  div.vue-html2pdf div.card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .RelatorioJurosPDF {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .margemTotalizador {
    margin-top: 30px;
  }

  .RelatorioJurosPDF tbody tr td {
    text-wrap: nowrap;
  }

  .mt-07 {
    margin-top: 0.7rem !important;
  }

  .mt-05 {
    margin-top: 0.5rem !important;
  }

  #radiosTipoDePesquisa > label {
    width: 0.5em;
  }
</style>
