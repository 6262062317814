<script>
  import Ripple from 'vue-ripple-directive'
  import docxtemplater from 'docxtemplater'
  import PizZip from 'pizzip'
  import { saveAs } from 'file-saver'
  import JSZipUtils from 'jszip-utils'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData } from '@/libs/utils'
  import { formatarCompetencia } from '@core/utils/utils.js'

  export default {
    components: {
      formatarData,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {
        type: Object,
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        bancarioitems: [],
        formatarData,
        formatarCompetencia,
      }
    },
    mounted() {
      this.carregarGridBancaria()
    },
    methods: {
      carregarGridBancaria() {
        useJwt
          .get(`cadastro/instituto/bancarioInst/${this.userData.institutoSelecionado}`)
          .then((response) => {
            response.data.map((item) => {
              if (item.dadoBancarioContaOpcao == 'Entrada' || item.dadoBancarioContaOpcao == 'EntradaeSaida')
                this.bancarioitems = item
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      loadFile(url, callback) {
        JSZipUtils.getBinaryContent(url, callback)
      },
      renderDoc(dataVencimento) {
        const data = {
          cabecalho: `GUIA DE ARRECADAÇÃO - Agenda Bank`,
          rpps: `${this.userData.Instituto.razaoSocial}`,
          orgao: `${this.parametro.orgao.nome}`,
          numero_grpe: `${this.parametro.codigo}`,
          competenciaGrpe: formatarCompetencia(this.parametro.competencia),
          vencimentoGrpe: formatarData(dataVencimento),
          cidadeInstituto: `${this.userData.Instituto.cidade}`,
          ufInstituto: `${this.userData.Instituto.uf}`,
          hoje: formatarData(new Date()),
          nomeResponsavelInstituto: `${this.userData.Instituto.nomeResponsavel}`,
          cargoResponsavelInstituto: `${this.userData.Instituto.cargoResponsavel}`,
          razaoSocialInstituto: `${this.userData.Instituto.razaoSocial}`,
          valorGrpe: `${
            this.parametro.valorGrpe == null
              ? 'R$ 0,00'
              : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.parametro.valorGrpe)
          }`,
          qtdTomador: `${this.parametro.quantidadeTomador}`,
          qtdContratos: `${this.parametro.quantidadeContratos}`,
          emissaoGrpe: formatarData(this.parametro.emissao),
          pagamentoGrpe: `${this.parametro.pagamento == null ? '' : formatarData(this.parametro.pagamento)}`,
          creditoGrpe: `R$ 0,00`,
          valorAtualizado: `${
            this.parametro.valorPago == null
              ? 'R$ 0,00'
              : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.parametro.valorPago)
          }`,
          valorJuros: `${
            this.parametro.valorJuros == null
              ? 'R$ 0,00'
              : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.parametro.valorJuros)
          }`,
          valorMulta: `${
            this.parametro.valorMulta == null
              ? 'R$ 0,00'
              : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.parametro.valorMulta)
          }`,
          valorTotalGrpe: `${
            this.parametro.valorGrpe == null
              ? 'R$ 0,00'
              : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.parametro.valorGrpe)
          }`,
          bancoInstituto: `${this.bancarioitems?.banco.nome}`,
          agInstituto: `${this.bancarioitems?.dadoBancarioAgencia} - ${this.bancarioitems?.dadoBancarioAgenciaDV}`,
          ccInstituto: `${this.bancarioitems?.dadoBancarioConta} - ${this.bancarioitems?.dadoBancarioConta}`,
          rodape: `${this.userData.Instituto.logradouro}, ${this.userData.Instituto.numero}, ${this.userData.Instituto.bairro} - ${this.userData.Instituto.cep}, ${this.userData.Instituto.cidade}/${this.userData.Instituto.uf}, ${this.userData.Instituto.telefone} - ${this.userData.Instituto.razaoSocial}`,
        }
        const component = this
        this.loadFile(`/modeloGuia.docx`, async function (error, content) {
          component.$message.info('Iniciando a geração da guia. Por favor, aguarde...')

          if (error) {
            console.error(error)
            throw error
          }

          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (error, key) {
                error[key] = value[key]
                return error
              }, {})
            }
            return value
          }

          function errorHandler(error) {
            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation
                })
                .join('\n')
              console.error(error)
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error
          }

          var zip = new PizZip(content)
          var doc
          try {
            doc = new docxtemplater(zip)
          } catch (error) {
            // Catch compilation errors (errors caused by the compilation of the template : misplaced tags)
            errorHandler(error)
          }

          doc.setData({
            cabecalho: data.cabecalho,
            rpps: data.rpps,
            orgao: data.orgao,
            numero_grpe: data.numero_grpe,
            competenciaGrpe: data.competenciaGrpe,
            vencimentoGrpe: data.vencimentoGrpe,
            cidadeInstituto: data.cidadeInstituto,
            ufInstituto: data.ufInstituto,
            hoje: data.hoje,
            nomeResponsavelInstituto: data.nomeResponsavelInstituto,
            cargoResponsavelInstituto: data.cargoResponsavelInstituto,
            razaoSocialInstituto: data.razaoSocialInstituto,
            valorGrpe: data.valorGrpe,
            qtdTomador: data.qtdTomador,
            qtdContratos: data.qtdContratos,
            emissaoGrpe: data.emissaoGrpe,
            pagamentoGrpe: data.pagamentoGrpe,
            creditoGrpe: data.creditoGrpe,
            valorAtualizado: data.valorAtualizado,
            valorJuros: data.valorJuros,
            valorMulta: data.valorMulta,
            valorTotalGrpe: data.valorTotalGrpe,
            bancoInstituto: data.bancoInstituto,
            agInstituto: data.agInstituto,
            ccInstituto: data.ccInstituto,
            rodape: data.rodape,
          })

          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render()
          } catch (error) {
            // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
            errorHandler(error)
          }

          var out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }) //Output the document using Data-URI

          var out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }) //Output the document using Data-URI

          const formData = new FormData()
          formData.append('file', out, 'guia')
          const res = (await useJwt.postDocumento(`financeiro/processaPdf`, (event) => {}, formData)).data.pdf

          const uint8Array = new Uint8Array(res.data)
          const blob = new Blob([uint8Array])

          saveAs(blob, 'Agenda Bank-Arrecadacao.pdf')
          return out
        })
      },
    },
  }
</script>

<style></style>
