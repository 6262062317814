/* tslint:disable */
/* eslint-disable */
/**
 * Agenda Bank API
 * API do sistema de consignações previdenciárias - Agenda Bank
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base'
// @ts-ignore
import { Instituto } from '../models'
// @ts-ignore
import { RestricoesCalculadoraExternaDto } from '../models'
// @ts-ignore
import { RestricoesEmprestimosConsignadosDto } from '../models'
// @ts-ignore
import { RestricoesRenegociacaoDto } from '../models'
// @ts-ignore
import { RestricoesTipoCalculoDto } from '../models'
/**
 * RestricoesApi - axios parameter creator
 * @export
 */
export const RestricoesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {RestricoesCalculadoraExternaDto} restricoesCalculadoraExternaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculadoraExterna: async (
      id: number,
      restricoesCalculadoraExternaDto: RestricoesCalculadoraExternaDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('calculadoraExterna', 'id', id)
      // verify required parameter 'restricoesCalculadoraExternaDto' is not null or undefined
      assertParamExists('calculadoraExterna', 'restricoesCalculadoraExternaDto', restricoesCalculadoraExternaDto)
      const localVarPath = `/instituto/restricoes/calculadoraExterna/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        restricoesCalculadoraExternaDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesEmprestimosConsignadosDto} restricoesEmprestimosConsignadosDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emprestimosConsignados: async (
      id: number,
      restricoesEmprestimosConsignadosDto: RestricoesEmprestimosConsignadosDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('emprestimosConsignados', 'id', id)
      // verify required parameter 'restricoesEmprestimosConsignadosDto' is not null or undefined
      assertParamExists('emprestimosConsignados', 'restricoesEmprestimosConsignadosDto', restricoesEmprestimosConsignadosDto)
      const localVarPath = `/instituto/restricoes/emprestimosConsignados/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        restricoesEmprestimosConsignadosDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesRenegociacaoDto} restricoesRenegociacaoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renegociacao: async (
      id: number,
      restricoesRenegociacaoDto: RestricoesRenegociacaoDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('renegociacao', 'id', id)
      // verify required parameter 'restricoesRenegociacaoDto' is not null or undefined
      assertParamExists('renegociacao', 'restricoesRenegociacaoDto', restricoesRenegociacaoDto)
      const localVarPath = `/instituto/restricoes/renegociacao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(restricoesRenegociacaoDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesTipoCalculoDto} restricoesTipoCalculoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tipoCalculo: async (
      id: number,
      restricoesTipoCalculoDto: RestricoesTipoCalculoDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tipoCalculo', 'id', id)
      // verify required parameter 'restricoesTipoCalculoDto' is not null or undefined
      assertParamExists('tipoCalculo', 'restricoesTipoCalculoDto', restricoesTipoCalculoDto)
      const localVarPath = `/instituto/restricoes/tipoCalculo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(restricoesTipoCalculoDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * RestricoesApi - functional programming interface
 * @export
 */
export const RestricoesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RestricoesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {RestricoesCalculadoraExternaDto} restricoesCalculadoraExternaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calculadoraExterna(
      id: number,
      restricoesCalculadoraExternaDto: RestricoesCalculadoraExternaDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calculadoraExterna(id, restricoesCalculadoraExternaDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['RestricoesApi.calculadoraExterna']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesEmprestimosConsignadosDto} restricoesEmprestimosConsignadosDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emprestimosConsignados(
      id: number,
      restricoesEmprestimosConsignadosDto: RestricoesEmprestimosConsignadosDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.emprestimosConsignados(
        id,
        restricoesEmprestimosConsignadosDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['RestricoesApi.emprestimosConsignados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesRenegociacaoDto} restricoesRenegociacaoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async renegociacao(
      id: number,
      restricoesRenegociacaoDto: RestricoesRenegociacaoDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.renegociacao(id, restricoesRenegociacaoDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['RestricoesApi.renegociacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesTipoCalculoDto} restricoesTipoCalculoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tipoCalculo(
      id: number,
      restricoesTipoCalculoDto: RestricoesTipoCalculoDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tipoCalculo(id, restricoesTipoCalculoDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['RestricoesApi.tipoCalculo']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
  }
}

/**
 * RestricoesApi - factory interface
 * @export
 */
export const RestricoesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RestricoesApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {RestricoesCalculadoraExternaDto} restricoesCalculadoraExternaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calculadoraExterna(
      id: number,
      restricoesCalculadoraExternaDto: RestricoesCalculadoraExternaDto,
      options?: any,
    ): AxiosPromise<Instituto> {
      return localVarFp
        .calculadoraExterna(id, restricoesCalculadoraExternaDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesEmprestimosConsignadosDto} restricoesEmprestimosConsignadosDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emprestimosConsignados(
      id: number,
      restricoesEmprestimosConsignadosDto: RestricoesEmprestimosConsignadosDto,
      options?: any,
    ): AxiosPromise<Instituto> {
      return localVarFp
        .emprestimosConsignados(id, restricoesEmprestimosConsignadosDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesRenegociacaoDto} restricoesRenegociacaoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    renegociacao(id: number, restricoesRenegociacaoDto: RestricoesRenegociacaoDto, options?: any): AxiosPromise<Instituto> {
      return localVarFp.renegociacao(id, restricoesRenegociacaoDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {RestricoesTipoCalculoDto} restricoesTipoCalculoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tipoCalculo(id: number, restricoesTipoCalculoDto: RestricoesTipoCalculoDto, options?: any): AxiosPromise<Instituto> {
      return localVarFp.tipoCalculo(id, restricoesTipoCalculoDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * RestricoesApi - object-oriented interface
 * @export
 * @class RestricoesApi
 * @extends {BaseAPI}
 */
export class RestricoesApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {RestricoesCalculadoraExternaDto} restricoesCalculadoraExternaDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestricoesApi
   */
  public calculadoraExterna(
    id: number,
    restricoesCalculadoraExternaDto: RestricoesCalculadoraExternaDto,
    options?: RawAxiosRequestConfig,
  ) {
    return RestricoesApiFp(this.configuration)
      .calculadoraExterna(id, restricoesCalculadoraExternaDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {RestricoesEmprestimosConsignadosDto} restricoesEmprestimosConsignadosDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestricoesApi
   */
  public emprestimosConsignados(
    id: number,
    restricoesEmprestimosConsignadosDto: RestricoesEmprestimosConsignadosDto,
    options?: RawAxiosRequestConfig,
  ) {
    return RestricoesApiFp(this.configuration)
      .emprestimosConsignados(id, restricoesEmprestimosConsignadosDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {RestricoesRenegociacaoDto} restricoesRenegociacaoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestricoesApi
   */
  public renegociacao(id: number, restricoesRenegociacaoDto: RestricoesRenegociacaoDto, options?: RawAxiosRequestConfig) {
    return RestricoesApiFp(this.configuration)
      .renegociacao(id, restricoesRenegociacaoDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {RestricoesTipoCalculoDto} restricoesTipoCalculoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestricoesApi
   */
  public tipoCalculo(id: number, restricoesTipoCalculoDto: RestricoesTipoCalculoDto, options?: RawAxiosRequestConfig) {
    return RestricoesApiFp(this.configuration)
      .tipoCalculo(id, restricoesTipoCalculoDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
