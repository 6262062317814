/* tslint:disable */
/* eslint-disable */
/**
 * Agenda Bank API
 * API do sistema de consignações previdenciárias - Agenda Bank
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base'
// @ts-ignore
import { GeraGRPE } from '../models'
// @ts-ignore
import { GetArrecadacao } from '../models'
// @ts-ignore
import { GetArrecadacaoResponse } from '../models'
// @ts-ignore
import { ListArrecadacao } from '../models'
// @ts-ignore
import { ListArrecadacaoResponse } from '../models'
// @ts-ignore
import { PutTipoBoletoDto } from '../models'
/**
 * ArrecadacaoApi - axios parameter creator
 * @export
 */
export const ArrecadacaoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamento: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/financeiro/Arrecadacao/confirmarPagamento`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {GeraGRPE} geraGRPE
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    criarArrecadacao: async (id: number, geraGRPE: GeraGRPE, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('criarArrecadacao', 'id', id)
      // verify required parameter 'geraGRPE' is not null or undefined
      assertParamExists('criarArrecadacao', 'geraGRPE', geraGRPE)
      const localVarPath = `/financeiro/Arrecadacao/criarArrecadacao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(geraGRPE, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetArrecadacao} getArrecadacao
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (getArrecadacao: GetArrecadacao, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'getArrecadacao' is not null or undefined
      assertParamExists('get', 'getArrecadacao', getArrecadacao)
      const localVarPath = `/financeiro/Arrecadacao/get`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getArrecadacao, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} mes
     * @param {number} ano
     * @param {number} orgaoIds
     * @param {number} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArrecadacao: async (
      mes: number,
      ano: number,
      orgaoIds: number,
      institutoId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'mes' is not null or undefined
      assertParamExists('getArrecadacao', 'mes', mes)
      // verify required parameter 'ano' is not null or undefined
      assertParamExists('getArrecadacao', 'ano', ano)
      // verify required parameter 'orgaoIds' is not null or undefined
      assertParamExists('getArrecadacao', 'orgaoIds', orgaoIds)
      // verify required parameter 'institutoId' is not null or undefined
      assertParamExists('getArrecadacao', 'institutoId', institutoId)
      const localVarPath = `/financeiro/Arrecadacao/getArrecadacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (mes !== undefined) {
        localVarQueryParameter['mes'] = mes
      }

      if (ano !== undefined) {
        localVarQueryParameter['ano'] = ano
      }

      if (orgaoIds !== undefined) {
        localVarQueryParameter['orgaoIds'] = orgaoIds
      }

      if (institutoId !== undefined) {
        localVarQueryParameter['institutoId'] = institutoId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ListArrecadacao} listArrecadacao
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listar: async (listArrecadacao: ListArrecadacao, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'listArrecadacao' is not null or undefined
      assertParamExists('listar', 'listArrecadacao', listArrecadacao)
      const localVarPath = `/financeiro/Arrecadacao/listar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(listArrecadacao, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PutTipoBoletoDto} putTipoBoletoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTipoBoleto: async (putTipoBoletoDto: PutTipoBoletoDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'putTipoBoletoDto' is not null or undefined
      assertParamExists('putTipoBoleto', 'putTipoBoletoDto', putTipoBoletoDto)
      const localVarPath = `/financeiro/Arrecadacao/putTipoBoleto`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(putTipoBoletoDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ArrecadacaoApi - functional programming interface
 * @export
 */
export const ArrecadacaoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArrecadacaoApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmarPagamento(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmarPagamento(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['ArrecadacaoApi.confirmarPagamento']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {GeraGRPE} geraGRPE
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async criarArrecadacao(
      id: number,
      geraGRPE: GeraGRPE,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.criarArrecadacao(id, geraGRPE, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['ArrecadacaoApi.criarArrecadacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetArrecadacao} getArrecadacao
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      getArrecadacao: GetArrecadacao,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetArrecadacaoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(getArrecadacao, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['ArrecadacaoApi.get']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} mes
     * @param {number} ano
     * @param {number} orgaoIds
     * @param {number} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getArrecadacao(
      mes: number,
      ano: number,
      orgaoIds: number,
      institutoId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getArrecadacao(mes, ano, orgaoIds, institutoId, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['ArrecadacaoApi.getArrecadacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {ListArrecadacao} listArrecadacao
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listar(
      listArrecadacao: ListArrecadacao,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListArrecadacaoResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listar(listArrecadacao, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['ArrecadacaoApi.listar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PutTipoBoletoDto} putTipoBoletoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putTipoBoleto(
      putTipoBoletoDto: PutTipoBoletoDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putTipoBoleto(putTipoBoletoDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['ArrecadacaoApi.putTipoBoleto']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
  }
}

/**
 * ArrecadacaoApi - factory interface
 * @export
 */
export const ArrecadacaoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArrecadacaoApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamento(options?: any): AxiosPromise<void> {
      return localVarFp.confirmarPagamento(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {GeraGRPE} geraGRPE
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    criarArrecadacao(id: number, geraGRPE: GeraGRPE, options?: any): AxiosPromise<void> {
      return localVarFp.criarArrecadacao(id, geraGRPE, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetArrecadacao} getArrecadacao
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(getArrecadacao: GetArrecadacao, options?: any): AxiosPromise<GetArrecadacaoResponse> {
      return localVarFp.get(getArrecadacao, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} mes
     * @param {number} ano
     * @param {number} orgaoIds
     * @param {number} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getArrecadacao(mes: number, ano: number, orgaoIds: number, institutoId: number, options?: any): AxiosPromise<void> {
      return localVarFp.getArrecadacao(mes, ano, orgaoIds, institutoId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ListArrecadacao} listArrecadacao
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listar(listArrecadacao: ListArrecadacao, options?: any): AxiosPromise<ListArrecadacaoResponse> {
      return localVarFp.listar(listArrecadacao, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PutTipoBoletoDto} putTipoBoletoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putTipoBoleto(putTipoBoletoDto: PutTipoBoletoDto, options?: any): AxiosPromise<void> {
      return localVarFp.putTipoBoleto(putTipoBoletoDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ArrecadacaoApi - object-oriented interface
 * @export
 * @class ArrecadacaoApi
 * @extends {BaseAPI}
 */
export class ArrecadacaoApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArrecadacaoApi
   */
  public confirmarPagamento(options?: RawAxiosRequestConfig) {
    return ArrecadacaoApiFp(this.configuration)
      .confirmarPagamento(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {GeraGRPE} geraGRPE
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArrecadacaoApi
   */
  public criarArrecadacao(id: number, geraGRPE: GeraGRPE, options?: RawAxiosRequestConfig) {
    return ArrecadacaoApiFp(this.configuration)
      .criarArrecadacao(id, geraGRPE, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetArrecadacao} getArrecadacao
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArrecadacaoApi
   */
  public get(getArrecadacao: GetArrecadacao, options?: RawAxiosRequestConfig) {
    return ArrecadacaoApiFp(this.configuration)
      .get(getArrecadacao, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} mes
   * @param {number} ano
   * @param {number} orgaoIds
   * @param {number} institutoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArrecadacaoApi
   */
  public getArrecadacao(mes: number, ano: number, orgaoIds: number, institutoId: number, options?: RawAxiosRequestConfig) {
    return ArrecadacaoApiFp(this.configuration)
      .getArrecadacao(mes, ano, orgaoIds, institutoId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ListArrecadacao} listArrecadacao
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArrecadacaoApi
   */
  public listar(listArrecadacao: ListArrecadacao, options?: RawAxiosRequestConfig) {
    return ArrecadacaoApiFp(this.configuration)
      .listar(listArrecadacao, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PutTipoBoletoDto} putTipoBoletoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArrecadacaoApi
   */
  public putTipoBoleto(putTipoBoletoDto: PutTipoBoletoDto, options?: RawAxiosRequestConfig) {
    return ArrecadacaoApiFp(this.configuration)
      .putTipoBoleto(putTipoBoletoDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
