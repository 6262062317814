/* tslint:disable */
/* eslint-disable */
/**
 * Agenda Bank API
 * API do sistema de consignações previdenciárias - Agenda Bank
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base'
// @ts-ignore
import { GetBuscaCepTomador } from '../models'
// @ts-ignore
import { GetIndicadoresTomador } from '../models'
// @ts-ignore
import { GetPodeExcluirSegurado } from '../models'
// @ts-ignore
import { Pesquisar } from '../models'
/**
 * TomadorApi - axios parameter creator
 * @export
 */
export const TomadorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/cadastro/tomador/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cadastrarFoto: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/cadastrarFoto`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadCertidaoObito: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('downloadCertidaoObito', 'id', id)
      const localVarPath = `/cadastro/tomador/downloadDCertidaoObito/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPortariaExoneracao: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('downloadPortariaExoneracao', 'id', id)
      const localVarPath = `/cadastro/tomador/downloadDPortariaExoneracao/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excluirCertidaoObito: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('excluirCertidaoObito', 'id', id)
      const localVarPath = `/cadastro/tomador/excluirCertidaoObito/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excluirPortariaExoneracaoController: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('excluirPortariaExoneracaoController', 'id', id)
      const localVarPath = `/cadastro/tomador/excluirPortariaExoneracao/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetBuscaCepTomador} getBuscaCepTomador
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBuscaCepTomador: async (
      getBuscaCepTomador: GetBuscaCepTomador,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getBuscaCepTomador' is not null or undefined
      assertParamExists('getBuscaCepTomador', 'getBuscaCepTomador', getBuscaCepTomador)
      const localVarPath = `/cadastro/tomador/GetBuscaCepTomador`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getBuscaCepTomador, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCertidaoObitoTomador: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getCertidaoObitoTomador', 'id', id)
      const localVarPath = `/cadastro/tomador/GetCertidaoObitoTomador{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getId', 'id', id)
      const localVarPath = `/cadastro/tomador/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetIndicadoresTomador} getIndicadoresTomador
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIndicadoresTomador: async (
      getIndicadoresTomador: GetIndicadoresTomador,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getIndicadoresTomador' is not null or undefined
      assertParamExists('getIndicadoresTomador', 'getIndicadoresTomador', getIndicadoresTomador)
      const localVarPath = `/cadastro/tomador/GetIndicadoresTomador`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getIndicadoresTomador, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetPodeExcluirSegurado} getPodeExcluirSegurado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPodeExcluirSegurado: async (
      getPodeExcluirSegurado: GetPodeExcluirSegurado,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getPodeExcluirSegurado' is not null or undefined
      assertParamExists('getPodeExcluirSegurado', 'getPodeExcluirSegurado', getPodeExcluirSegurado)
      const localVarPath = `/cadastro/tomador/GetPodeExcluirSegurado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getPodeExcluirSegurado, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortariaExoneracaoSegurado: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPortariaExoneracaoSegurado', 'id', id)
      const localVarPath = `/cadastro/tomador/GetPortariaExoneracaoSegurado/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorioTomador: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/GetRelatorioTomador`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSegurados: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getSegurados', 'id', id)
      const localVarPath = `/cadastro/tomador/GetSegurados/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimulacaoPropostaSegurados: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getSimulacaoPropostaSegurados', 'pesquisar', pesquisar)
      const localVarPath = `/cadastro/tomador/GetSimulacaoPropostaSegurados`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/Getpesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importaMargemSegurado: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/importaMargemSegurado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    limparFoto: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('limparFoto', 'id', id)
      const localVarPath = `/cadastro/tomador/limparFoto/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaCertidaoObito: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/novaCertidaoObito`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaPortariaExoneracao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/novaPortariaExoneracao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/cadastro/tomador/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSegurado: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateSegurado', 'id', id)
      const localVarPath = `/cadastro/tomador/updateSegurado/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCertidaoObito: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/uploadCertidaoObito`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPortariaExoneracao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/tomador/uploadPortariaExoneracao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TomadorApi - functional programming interface
 * @export
 */
export const TomadorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TomadorApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi._delete']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cadastrarFoto(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cadastrarFoto(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.cadastrarFoto']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadCertidaoObito(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadCertidaoObito(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.downloadCertidaoObito']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadPortariaExoneracao(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPortariaExoneracao(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.downloadPortariaExoneracao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async excluirCertidaoObito(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.excluirCertidaoObito(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.excluirCertidaoObito']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async excluirPortariaExoneracaoController(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.excluirPortariaExoneracaoController(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.excluirPortariaExoneracaoController']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetBuscaCepTomador} getBuscaCepTomador
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBuscaCepTomador(
      getBuscaCepTomador: GetBuscaCepTomador,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBuscaCepTomador(getBuscaCepTomador, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getBuscaCepTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCertidaoObitoTomador(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCertidaoObitoTomador(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getCertidaoObitoTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getId(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getId(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getId']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetIndicadoresTomador} getIndicadoresTomador
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIndicadoresTomador(
      getIndicadoresTomador: GetIndicadoresTomador,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicadoresTomador(getIndicadoresTomador, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getIndicadoresTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetPodeExcluirSegurado} getPodeExcluirSegurado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPodeExcluirSegurado(
      getPodeExcluirSegurado: GetPodeExcluirSegurado,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPodeExcluirSegurado(getPodeExcluirSegurado, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getPodeExcluirSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPortariaExoneracaoSegurado(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPortariaExoneracaoSegurado(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getPortariaExoneracaoSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatorioTomador(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioTomador(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getRelatorioTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSegurados(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSegurados(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getSegurados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSimulacaoPropostaSegurados(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSimulacaoPropostaSegurados(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getSimulacaoPropostaSegurados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getpesquisar(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisar(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.getpesquisar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importaMargemSegurado(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importaMargemSegurado(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.importaMargemSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async limparFoto(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.limparFoto(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.limparFoto']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaCertidaoObito(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaCertidaoObito(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.novaCertidaoObito']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaPortariaExoneracao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaPortariaExoneracao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.novaPortariaExoneracao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.post']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.put']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSegurado(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSegurado(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.updateSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadCertidaoObito(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCertidaoObito(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.uploadCertidaoObito']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadPortariaExoneracao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPortariaExoneracao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['TomadorApi.uploadPortariaExoneracao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
  }
}

/**
 * TomadorApi - factory interface
 * @export
 */
export const TomadorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TomadorApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cadastrarFoto(options?: any): AxiosPromise<void> {
      return localVarFp.cadastrarFoto(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadCertidaoObito(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.downloadCertidaoObito(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPortariaExoneracao(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.downloadPortariaExoneracao(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excluirCertidaoObito(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.excluirCertidaoObito(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excluirPortariaExoneracaoController(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.excluirPortariaExoneracaoController(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetBuscaCepTomador} getBuscaCepTomador
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBuscaCepTomador(getBuscaCepTomador: GetBuscaCepTomador, options?: any): AxiosPromise<void> {
      return localVarFp.getBuscaCepTomador(getBuscaCepTomador, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCertidaoObitoTomador(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getCertidaoObitoTomador(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getId(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetIndicadoresTomador} getIndicadoresTomador
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIndicadoresTomador(getIndicadoresTomador: GetIndicadoresTomador, options?: any): AxiosPromise<void> {
      return localVarFp.getIndicadoresTomador(getIndicadoresTomador, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetPodeExcluirSegurado} getPodeExcluirSegurado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPodeExcluirSegurado(getPodeExcluirSegurado: GetPodeExcluirSegurado, options?: any): AxiosPromise<void> {
      return localVarFp.getPodeExcluirSegurado(getPodeExcluirSegurado, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPortariaExoneracaoSegurado(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getPortariaExoneracaoSegurado(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorioTomador(options?: any): AxiosPromise<void> {
      return localVarFp.getRelatorioTomador(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSegurados(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getSegurados(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimulacaoPropostaSegurados(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getSimulacaoPropostaSegurados(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar(options?: any): AxiosPromise<void> {
      return localVarFp.getpesquisar(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importaMargemSegurado(options?: any): AxiosPromise<void> {
      return localVarFp.importaMargemSegurado(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    limparFoto(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.limparFoto(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaCertidaoObito(options?: any): AxiosPromise<void> {
      return localVarFp.novaCertidaoObito(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaPortariaExoneracao(options?: any): AxiosPromise<void> {
      return localVarFp.novaPortariaExoneracao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(options?: any): AxiosPromise<void> {
      return localVarFp.post(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.put(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSegurado(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.updateSegurado(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadCertidaoObito(options?: any): AxiosPromise<void> {
      return localVarFp.uploadCertidaoObito(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadPortariaExoneracao(options?: any): AxiosPromise<void> {
      return localVarFp.uploadPortariaExoneracao(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * TomadorApi - object-oriented interface
 * @export
 * @class TomadorApi
 * @extends {BaseAPI}
 */
export class TomadorApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public _delete(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      ._delete(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public cadastrarFoto(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .cadastrarFoto(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public downloadCertidaoObito(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .downloadCertidaoObito(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public downloadPortariaExoneracao(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .downloadPortariaExoneracao(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public excluirCertidaoObito(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .excluirCertidaoObito(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public excluirPortariaExoneracaoController(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .excluirPortariaExoneracaoController(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetBuscaCepTomador} getBuscaCepTomador
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getBuscaCepTomador(getBuscaCepTomador: GetBuscaCepTomador, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getBuscaCepTomador(getBuscaCepTomador, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getCertidaoObitoTomador(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getCertidaoObitoTomador(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getId(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getId(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetIndicadoresTomador} getIndicadoresTomador
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getIndicadoresTomador(getIndicadoresTomador: GetIndicadoresTomador, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getIndicadoresTomador(getIndicadoresTomador, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetPodeExcluirSegurado} getPodeExcluirSegurado
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getPodeExcluirSegurado(getPodeExcluirSegurado: GetPodeExcluirSegurado, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getPodeExcluirSegurado(getPodeExcluirSegurado, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getPortariaExoneracaoSegurado(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getPortariaExoneracaoSegurado(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getRelatorioTomador(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getRelatorioTomador(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getSegurados(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getSegurados(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getSimulacaoPropostaSegurados(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getSimulacaoPropostaSegurados(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public getpesquisar(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .getpesquisar(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public importaMargemSegurado(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .importaMargemSegurado(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public limparFoto(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .limparFoto(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public novaCertidaoObito(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .novaCertidaoObito(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public novaPortariaExoneracao(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .novaPortariaExoneracao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public post(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .post(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public put(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .put(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public updateSegurado(id: number, options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .updateSegurado(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public uploadCertidaoObito(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .uploadCertidaoObito(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TomadorApi
   */
  public uploadPortariaExoneracao(options?: RawAxiosRequestConfig) {
    return TomadorApiFp(this.configuration)
      .uploadPortariaExoneracao(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
