import { format } from 'date-fns'

export function formatarDataSeguro(data: Date | string) {
  if (!data) return ''
  if (typeof data === 'string') {
    data = new Date(data)
  }
  if (isNaN(data.getTime())) return ''

  return format(data, 'dd/MM/yyyy')
}
