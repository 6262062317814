import { Bancos, Grpe, Instituto, InstitutoContaBancaria, Orgao } from '@/prisma'
import Ripple from 'vue-ripple-directive'
import docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import { saveAs } from 'file-saver'
import JSZipUtils from 'jszip-utils'
import useJwt from '@/auth/jwt/useJwt'
import { formatarData } from '@/libs/utils'
import { formatarCompetencia } from '@/@core/utils/utils.js'
import { formatarDataSeguro } from '@/formatters'

/**
 * Gera um objeto contendo os dados para a geração de uma guia de arrecadação.
 * @param grpe - Objeto contendo os dados do GRPE (Guia de Recolhimento da Previdência Social).
 * @param orgao - Objeto contendo os dados do órgão.
 * @param instituto - Objeto contendo os dados do instituto.
 * @returns Um objeto contendo os dados para a geração da guia de arrecadação.
 */
export function gerarDadosGuia({
  grpe,
  orgao,
  instituto,
}: {
  grpe: Grpe
  orgao: Orgao
  instituto: {
    conta: InstitutoContaBancaria
    banco: Bancos
    dados: Instituto
  }
}) {
  return {
    cabecalho: `GUIA DE ARRECADAÇÃO - Agenda Bank`,
    rpps: `${instituto.dados.razaoSocial}`,
    orgao: `${orgao.nome}`,
    numero_grpe: `${grpe.codigo}`,
    competenciaGrpe: formatarCompetencia(grpe.competencia),
    vencimentoGrpe: formatarDataSeguro(grpe.dataVencimento),
    cidadeInstituto: `${instituto.dados.cidade}`,
    ufInstituto: `${instituto.dados.uf}`,
    hoje: formatarDataSeguro(new Date()),
    nomeResponsavelInstituto: `${instituto.dados.nomeResponsavel}`,
    cargoResponsavelInstituto: `${instituto.dados.cargoResponsavel}`,
    razaoSocialInstituto: `${instituto.dados.razaoSocial}`,
    valorGrpe: `${
      grpe.valorGrpe == null
        ? 'R$ 0,00'
        : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(grpe.valorGrpe as any)
    }`,
    qtdTomador: `${grpe.quantidadeTomador}`,
    qtdContratos: `${grpe.quantidadeContratos}`,
    emissaoGrpe: formatarDataSeguro(grpe.emissao),
    pagamentoGrpe: `${grpe.pagamento == null ? '' : formatarDataSeguro(grpe.pagamento)}`,
    creditoGrpe: `R$ 0,00`,
    valorAtualizado: `${
      grpe.valorPago == null
        ? 'R$ 0,00'
        : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(grpe.valorPago as any)
    }`,
    valorJuros: `${
      grpe.valorJuros == null
        ? 'R$ 0,00'
        : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(grpe.valorJuros as any)
    }`,
    valorMulta: `${
      grpe.valorMulta == null
        ? 'R$ 0,00'
        : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(grpe.valorMulta as any)
    }`,
    valorTotalGrpe: `${
      grpe.valorGrpe == null
        ? 'R$ 0,00'
        : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(grpe.valorGrpe as any)
    }`,
    bancoInstituto: `${instituto?.banco?.nome}`,
    agInstituto: `${instituto.conta?.dadoBancarioAgencia} - ${instituto.conta?.dadoBancarioAgenciaDV}`,
    ccInstituto: `${instituto.conta?.dadoBancarioConta} - ${instituto.conta?.dadoBancarioConta}`,
    rodape: `${instituto.dados.logradouro}, ${instituto.dados.numero}, ${instituto.dados.bairro} - ${instituto.dados.cep}, ${instituto.dados.cidade}/${instituto.dados.uf}, ${instituto.dados.telefone} - ${instituto.dados.razaoSocial}`,
  }
}
